import axios from 'axios'
export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        company: null,
        error: null
    },
    getters: {
        authenticated (state) {
            return state.token
        },
        user (state) {
            return state.user
        },
        company (state) {
            return state.company
        },
        error (state) {
            return state.error
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_USER(state, user) {
            state.user = user
        },
        SET_COMPANY(state, company) {
            state.company = company
        }, 
        SET_ERROR(state, error) {
            state.error = error
        }
    },
    actions: {
        async login ( { dispatch }, credential) {
            let response = await axios.post('/token', credential)
            dispatch('attempt', response.data)
        },
        async attempt( { commit, state }, data) {
            if(data.token) {
                commit('SET_ERROR', null)
                commit('SET_TOKEN', data.token)
                commit('SET_USER', data.user)
            } if(data.error) {
                commit('SET_ERROR', data.error)
            }

            if(!state.token) {
                return
            }
        },
        setToken ({ commit }, token) {
            commit('SET_TOKEN', token)
        }
    }
}